<div class="row mx-0">
    <div class="psedoHeight"></div>
    <div class="col-12 px-0 header-shadow position-fixed py-2 px-3 bg-white">
        <div class="row mx-0 justify-content-center h-100 text-center">
            <div class="w-100 d-flex header-max-width">
                <div class="">
                    <img height="50" width="150" src="../../../assets/images/meta/jodi365-logo.png" alt="jodi365-logo">
                </div>
                <div class="d-flex align-items-center ml-auto opensans-regular c-lightgray">
                    <a class="header-link clickable" (click)="activeURL('dashboard')" [ngClass]="checkActiveURL == '1' ? 'c-btn-primary' : 'c-lightgray'">
                        Dashboard
                    </a>

                    <a class="ml-3 header-link  clickable dropdown-toggle"  
                    [matMenuTriggerFor]="analytics" #AnalyticsTrigger="matMenuTrigger" (click)="AnalyticsTrigger.openMenu(); activeURL('analytics')" [ngClass]="checkActiveURL == '2' ? 'c-btn-primary' : 'c-lightgray'">
                        Analytics
                    </a>
                    <mat-menu #analytics="matMenu" [overlapTrigger]="false" xPosition="before">
                        <span (mouseleave)="AnalyticsTrigger.closeMenu()">
                            <!-- <div class="header-link">
                                Metrics
                            </div><br>
                            <div class="header-link">
                                Reports
                            </div> -->
                            <button  mat-menu-item  [ngClass]="dropDownActiveURL == '3' ? 'c-btn-primary' : 'c-lightgray'"><i class="fa fa-user mr-2"></i>
                                Metrics
                            </button>
                            <button mat-menu-item [ngClass]="dropDownActiveURL == '3' ? 'c-btn-primary' : 'c-lightgray'"><i class="fa fa-user mr-2"></i>
                                Reports
                            </button>
                            <button (click)="activeURLdropDown('NPS')" mat-menu-item class="clickable"
                            routerLinkActive="router-link-active" #proSet="routerLinkActive" [ngClass]="dropDownActiveURL == '7' ? 'c-btn-primary' : 'c-lightgray'">
                            <i class="fa fa-user mr-2"></i>NPS
                            </button>
                        </span>
                    </mat-menu>
                   
                    <a (click)="activeURL('segments')" class="ml-3 header-link clickable" [ngClass]="checkActiveURL == '3' ? 'c-btn-primary' : 'c-lightgray'">
                        Segments
                    </a>

                    <a  class="ml-3 header-link ">
                        Leads
                    </a>

                    <a  class="ml-3 header-link clickable dropdown-toggle" [matMenuTriggerFor]="logs" #LogTrigger="matMenuTrigger" (click)="LogTrigger.openMenu(); activeURL('logs')" [ngClass]="checkActiveURL == '5' ? 'c-btn-primary' : 'c-lightgray'">
                        Logs
                    </a>
                    <mat-menu #logs="matMenu" [overlapTrigger]="false" xPosition="before">
                        <span (mouseleave)="LogTrigger.closeMenu()">
                            <!-- <button  mat-menu-item [routerLink]="['']" class=""
                                routerLinkActive="router-link-active" #proSet="routerLinkActive" [ngClass]="dropDownActiveURL == '3' ? 'c-btn-primary' : 'c-lightgray'"><i class="fa fa-user mr-2"></i>
                                All
                            </button> -->
                            <button (click)="activeURLdropDown('call')" mat-menu-item [routerLink]="['']" class=""
                                routerLinkActive="router-link-active" #proSet="routerLinkActive" [ngClass]="dropDownActiveURL == '4' ? 'c-btn-primary' : 'c-lightgray'"><i class="fa fa-user mr-2"></i>
                                Call
                            </button>
                            <!-- <button  mat-menu-item [routerLink]="['']" class=""
                                routerLinkActive="router-link-active" #proSet="routerLinkActive" [ngClass]="dropDownActiveURL == '5' ? 'c-btn-primary' : 'c-lightgray'"><i class="fa fa-user mr-2"></i>
                                SMS
                            </button> -->
                        </span>
                    </mat-menu>

                    <a (click)="activeURL('moderation')" class="ml-3 header-link clickable"  [ngClass]="checkActiveURL == '6' ? 'c-btn-primary' : 'c-lightgray'">
                        Moderation
                    </a>

                    <a  class="ml-3 header-link ">
                        Calender
                    </a>

                    <a class="ml-3 header-link">
                        Notifications
                    </a>

                    <a  class="ml-3 header-link  clickable dropdown-toggle"  
                    [matMenuTriggerFor]="account" #AccountTrigger="matMenuTrigger" (click)="AccountTrigger.openMenu(); activeURL('account')" [ngClass]="checkActiveURL == '9' ? 'c-btn-primary' : 'c-lightgray'">
                    {{userName}}
                    </a>
                    <mat-menu #account="matMenu" [overlapTrigger]="false" xPosition="before">
                        <span>
                            <button  (click)="activeURLdropDown('account-details')" mat-menu-item class="clickable c-lightgray" [ngClass]="dropDownActiveURL == '8' ? 'c-btn-primary' : 'c-lightgray'"
                                routerLinkActive="router-link-active" #proSet="routerLinkActive">
                                Account 
                            </button>
                        </span>
                        <span (mouseleave)="AccountTrigger.closeMenu()">
                            <button  (click)="activeURLdropDown('logout')" mat-menu-item [routerLink]="['']" class="clickable"
                                routerLinkActive="router-link-active" #proSet="routerLinkActive" [ngClass]="dropDownActiveURL == '6' ? 'c-btn-primary' : 'c-lightgray'">
                                Logout
                            </button>
                        </span>
                    </mat-menu>
                </div>
              
            </div>

               
        </div>
    </div>
</div>
