import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  checkActiveURL ;
  dropDownActiveURL ;
  userName;

  constructor(private router: Router, private localservice: LocalStorageService, private authService : AuthService, private sharedService: SharedService) { 

    const res = localservice.getFromLocal('user');
    if(res){
      this.userName = res.firstname;
    }
  }

  ngOnInit(): void {
    // condition based for admin/reviewer/supervisor calling
    if(!this.sharedService.userType.isAdmin) {
      this.checkActiveURL = 1;
      const temp = window.location.pathname.split('/admin/')[1];
      if(temp == 'dashboard'){
        this.checkActiveURL = 1;
      } else if(temp == 'moderation'){
        this.checkActiveURL = 6;
      } else if(temp == 'profile'){
        this.checkActiveURL = 6;
      } else if(temp == 'analytics/nps'){
        this.checkActiveURL = 2;
        this.dropDownActiveURL = 7;
      } else if(temp == 'call-logs'){
        this.checkActiveURL = 5;
        this.dropDownActiveURL = 4;
      }else if(temp == 'activeuser'){
        this.checkActiveURL = 3;
      } 
    } else {
      this.checkActiveURL  = 9;
      this.dropDownActiveURL = 8;
      this.router.navigate(['/admin-console'])
    } 
  }

  activeURL(url){
    // condition based for admin/reviewer/supervisor calling
    if(!this.sharedService.userType.isAdmin){
      if(url == 'dashboard'){
        this.checkActiveURL  = 1;
        this.router.navigate(['/admin/dashboard']);
      } else if(url == 'analytics'){
        // this.checkActiveURL  = 2;
      } else if(url == 'segments'){
        this.router.navigate(['/admin/activeuser']);
        this.dropDownActiveURL = -1;
        this.checkActiveURL  = 3;
      } else if(url == 'leads'){
        this.checkActiveURL  = 4;
        this.router.navigate(['/admin/dashboard']);
      } else if(url == 'logs'){
      // this.checkActiveURL  = 5;
        //this.router.navigate(['/admin/dashboard']);
      } else if(url == 'moderation'){
        this.dropDownActiveURL = -1;
        this.checkActiveURL  = 6;
        this.router.navigate(['/RefreshComponent'], { skipLocationChange: false }).then(() => {
          this.router.navigate(['/admin/moderation']);
      });
      } else if(url == 'calender'){
        this.checkActiveURL  = 7;
        this.router.navigate(['/admin/dashboard']);
      } else if(url == 'notifications'){
        this.checkActiveURL  = 8;
        this.router.navigate(['/admin/dashboard']);
      } else if(url == 'account'){
        // this.checkActiveURL  = 9;
      }
    }
  }

  activeURLdropDown(URL){
  // condition based for admin/reviewer/supervisor calling
    if(!this.sharedService.userType.isAdmin) {
      if(URL == 'metrics'){
        this.dropDownActiveURL = 1;
        this.checkActiveURL  = 2;
        this.router.navigate(['/admin/dashboard']);
      } else if(URL == 'reports'){
        this.dropDownActiveURL = 2;
        this.checkActiveURL  = 2;
        this.router.navigate(['/admin/dashboard']);
      } else if(URL == 'all'){
        this.dropDownActiveURL = 3;
        this.router.navigate(['/admin/dashboard']);
      } else if(URL == 'call'){
        this.dropDownActiveURL = 4;
        this.checkActiveURL  = 5;
        this.router.navigate(['/admin/call-logs']);
      } else if(URL == 'sms'){
        this.dropDownActiveURL = 5;
        this.router.navigate(['/admin/dashboard']);
      } else if(URL == 'logout'){
        this.dropDownActiveURL = 6;
        this.checkActiveURL  = 9;
        this.localservice.removeToken('user');
        this.authService.logOut();
        this.router.navigate(['/login']);
      } else if(URL == 'NPS'){
        this.dropDownActiveURL = 7;
        this.checkActiveURL  = 2;
        this.router.navigate(['/admin/analytics/nps']);
      } else if(URL == 'account-details'){
        this.activeURLdropDownForAccont();
      }
    } else {
      if(URL == 'logout'){
        this.dropDownActiveURL = 6;
        this.checkActiveURL  = 9;
        this.localservice.removeToken('user');
        this.authService.logOut();
        this.router.navigate(['/login']);
      } else if(URL == 'account-details'){
        this.checkActiveURL  = 9;
        this.dropDownActiveURL = 8;
        this.router.navigate(['/admin-console'])
      }
    }
    
  }
  activeURLdropDownForAccont(){
    this.checkActiveURL  = 9;
    this.dropDownActiveURL = 8;
    this.router.navigate(['/admin/account-details'])
  }
}
